.full-height {
    height: 100%;
}
h1 {
    text-align: center;
}
.top-nav {
    display: flex;
    justify-content: center;
}
.top-nav-button {
    display:table-cell;
    vertical-align:middle;
}
