body {
  margin: 0;
  font-family: 'Montserrat', 'Arial',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.header {
    display: flex;
    justify-content: center;
}

.header img {
    display:table-cell;
    vertical-align:middle;
    height: 100px;
    width: 100px;
}

.header h1 {
    display:table-cell;
    vertical-align:middle;
}
